import { FoProperty, Property, StyleName } from '../../generator/styles';
import {
  Autogenerated,
  Formatter,
  getInitStore,
  Length,
  Model,
  OtVersion,
  PluginModel,
} from '../../generator/Model';
import { Field } from 'formik';
import React from 'react';

export const nextValue = (value: string, direction: 1 | -1) => {
  const unit = value.replaceAll(/[\d\.]+/g, '');
  const num = Number(unit ? value.substring(0, value.indexOf(unit)) : value);
  if (Number.isNaN(num)) {
    return value;
  }
  let res: number;
  switch (unit) {
    case 'mm':
    case 'pt':
    case 'pc':
    case 'px':
      res = num + direction;
      break;
    case '':
    case 'cm':
    case 'in':
    case 'em':
    default:
      // Hack around floating point number increments
      res = (num * 10 + 1 * direction) / 10;
      break;
  }
  return `${res}${unit}`;
};

export enum PageSizes {
  A3 = '297mm 420mm',
  A4 = '210mm 297mm',
  A5 = '148mm 210mm',
  EXECUTIVE = '184.1mm 266.7mm',
  JIS_B5 = '182mm 257mm',
  TABLOID = '431.8mm 279.4mm',
  US_LEGAL = '8.5in 14in',
  US_LETTER = '8.5in 11in',
  PA4 = '210mm 280mm',
}

export type Values = {
  cover_image_chooser: 'metadata' | '';
  page_size: PageSizes;
  orientation: 'portrait' | 'landscape';
  style_selector: StyleName;
} & PluginModel;

export function toPluginModel(values: Values): PluginModel {
  const pageDimensions = values.page_size.split(' ');
  if (values.orientation === 'landscape') {
    pageDimensions.reverse();
  }
  const model = {
    ...values,
    page: {
      ...values.page,
      height: pageDimensions[1],
      width: pageDimensions[0],
    },
  };
  return model;
}

export function toTemplateModel(values: Values): Model {
  const pageDimensions = values.page_size.split(' ');
  if (values.orientation === 'landscape') {
    pageDimensions.reverse();
  }
  const model: any = {
    ...values,
    page: {
      ...values.page,
      height: pageDimensions[1],
      width: pageDimensions[0],
    },
  };
  delete model.id;
  delete model.formatter;
  delete model.plugin_name;
  delete model.plugin_version;
  delete model.transtype;
  delete model.page_size;
  delete model.orientation;
  delete model.style_selector;
  delete model.cover_image_chooser;
  delete model.ot_version;
  return model as Model;
}

export function getInitValues(): Values {
  const listProperties: Property[] = [
    FoProperty.COLOR,
    FoProperty.BACKGROUND_COLOR,
    FoProperty.LINE_HEIGHT,
  ];
  const init = getInitStore();
  return {
    ...init,
    style: Object.fromEntries(
      Object.entries(init.style).map(([styleName, value]) => {
        const lists = Object.fromEntries(
          listProperties.map((list) => [`${list}-list`, value[list]])
        );
        return [
          styleName,
          {
            ...value,
            ...lists,
          },
        ];
      })
    ) as any,
    cover_image_chooser: '',
    page_size: PageSizes.A4,
    orientation: 'portrait',
    style_selector: StyleName.BODY,
  };
}
