export enum FoProperty {
  ABSOLUTE_POSITION = 'absolute-position',
  ACTIVE_STATE = 'active-state',
  ALIGNMENT_ADJUST = 'alignment-adjust',
  ALIGNMENT_BASELINE = 'alignment-baseline',
  ALLOWED_HEIGHT_SCALE = 'allowed-height-scale',
  ALLOWED_WIDTH_SCALE = 'allowed-width-scale',
  AUTO_RESTORE = 'auto-restore',
  AZIMUTH = 'azimuth',
  BACKGROUND_ATTACHMENT = 'background-attachment',
  BACKGROUND_COLOR = 'background-color',
  BACKGROUND_IMAGE = 'background-image',
  BACKGROUND_POSITION_HORIZONTAL = 'background-position-horizontal',
  BACKGROUND_POSITION_VERTICAL = 'background-position-vertical',
  BACKGROUND_REPEAT = 'background-repeat',
  BASELINE_SHIFT = 'baseline-shift',
  BLANK_OR_NOT_BLANK = 'blank-or-not-blank',
  BLOCK_PROGRESSION_DIMENSION = 'block-progression-dimension',
  // BORDER = 'border',
  BORDER_AFTER_COLOR = 'border-after-color',
  BORDER_AFTER_PRECEDENCE = 'border-after-precedence',
  BORDER_AFTER_STYLE = 'border-after-style',
  BORDER_AFTER_WIDTH = 'border-after-width',
  BORDER_BEFORE_COLOR = 'border-before-color',
  BORDER_BEFORE_PRECEDENCE = 'border-before-precedence',
  BORDER_BEFORE_STYLE = 'border-before-style',
  BORDER_BEFORE_WIDTH = 'border-before-width',
  BORDER_BOTTOM_COLOR = 'border-bottom-color',
  BORDER_BOTTOM_STYLE = 'border-bottom-style',
  BORDER_BOTTOM_WIDTH = 'border-bottom-width',
  BORDER_COLLAPSE = 'border-collapse',
  BORDER_END_COLOR = 'border-end-color',
  BORDER_END_PRECEDENCE = 'border-end-precedence',
  BORDER_END_STYLE = 'border-end-style',
  BORDER_END_WIDTH = 'border-end-width',
  BORDER_LEFT_COLOR = 'border-left-color',
  BORDER_LEFT_STYLE = 'border-left-style',
  BORDER_LEFT_WIDTH = 'border-left-width',
  BORDER_RIGHT_COLOR = 'border-right-color',
  BORDER_RIGHT_STYLE = 'border-right-style',
  BORDER_RIGHT_WIDTH = 'border-right-width',
  BORDER_SEPARATION = 'border-separation',
  BORDER_START_COLOR = 'border-start-color',
  BORDER_START_PRECEDENCE = 'border-start-precedence',
  BORDER_START_STYLE = 'border-start-style',
  BORDER_START_WIDTH = 'border-start-width',
  BORDER_TOP_COLOR = 'border-top-color',
  BORDER_TOP_STYLE = 'border-top-style',
  BORDER_TOP_WIDTH = 'border-top-width',
  BOTTOM = 'bottom',
  BREAK_AFTER = 'break-after',
  BREAK_BEFORE = 'break-before',
  CAPTION_SIDE = 'caption-side',
  CASE_NAME = 'case-name',
  CASE_TITLE = 'case-title',
  CHANGE_BAR_CLASS = 'change-bar-class',
  CHANGE_BAR_COLOR = 'change-bar-color',
  CHANGE_BAR_OFFSET = 'change-bar-offset',
  CHANGE_BAR_PLACEMENT = 'change-bar-placement',
  CHANGE_BAR_STYLE = 'change-bar-style',
  CHANGE_BAR_WIDTH = 'change-bar-width',
  CHARACTER = 'character',
  CLEAR = 'clear',
  CLIP = 'clip',
  COLOR = 'color',
  COLOR_PROFILE_NAME = 'color-profile-name',
  COLUMN_COUNT = 'column-count',
  COLUMN_GAP = 'column-gap',
  COLUMN_NUMBER = 'column-number',
  COLUMN_WIDTH = 'column-width',
  CONTENT_HEIGHT = 'content-height',
  CONTENT_TYPE = 'content-type',
  CONTENT_WIDTH = 'content-width',
  COUNTRY = 'country',
  CUE_AFTER = 'cue-after',
  CUE_BEFORE = 'cue-before',
  DESTINATION_PLACEMENT_OFFSET = 'destination-placement-offset',
  DIRECTION = 'direction',
  DISPLAY_ALIGN = 'display-align',
  DOMINANT_BASELINE = 'dominant-baseline',
  ELEVATION = 'elevation',
  EMPTY_CELLS = 'empty-cells',
  END_INDENT = 'end-indent',
  ENDS_ROW = 'ends-row',
  EXTENT = 'extent',
  EXTERNAL_DESTINATION = 'external-destination',
  FLOAT = 'float',
  FLOW_MAP_NAME = 'flow-map-name',
  FLOW_MAP_REFERENCE = 'flow-map-reference',
  FLOW_NAME = 'flow-name',
  FLOW_NAME_REFERENCE = 'flow-name-reference',
  FONT_FAMILY = 'font-family',
  FONT_SELECTION_STRATEGY = 'font-selection-strategy',
  FONT_SIZE = 'font-size',
  FONT_SIZE_ADJUST = 'font-size-adjust',
  FONT_STRETCH = 'font-stretch',
  FONT_STYLE = 'font-style',
  FONT_VARIANT = 'font-variant',
  FONT_WEIGHT = 'font-weight',
  FORCE_PAGE_COUNT = 'force-page-count',
  FORMAT = 'format',
  GLYPH_ORIENTATION_HORIZONTAL = 'glyph-orientation-horizontal',
  GLYPH_ORIENTATION_VERTICAL = 'glyph-orientation-vertical',
  GROUPING_SEPARATOR = 'grouping-separator',
  GROUPING_SIZE = 'grouping-size',
  HEIGHT = 'height',
  HYPHENATE = 'hyphenate',
  HYPHENATION_CHARACTER = 'hyphenation-character',
  HYPHENATION_KEEP = 'hyphenation-keep',
  HYPHENATION_LADDER_COUNT = 'hyphenation-ladder-count',
  HYPHENATION_PUSH_CHARACTER_COUNT = 'hyphenation-push-character-count',
  HYPHENATION_REMAIN_CHARACTER_COUNT = 'hyphenation-remain-character-count',
  ID = 'id',
  INDEX_CLASS = 'index-class',
  INDEX_KEY = 'index-key',
  INDICATE_DESTINATION = 'indicate-destination',
  INITIAL_PAGE_NUMBER = 'initial-page-number',
  INLINE_PROGRESSION_DIMENSION = 'inline-progression-dimension',
  INTERNAL_DESTINATION = 'internal-destination',
  INTRINSIC_SCALE_VALUE = 'intrinsic-scale-value',
  INTRUSION_DISPLACE = 'intrusion-displace',
  KEEP_TOGETHER = 'keep-together',
  KEEP_WITH_NEXT = 'keep-with-next',
  KEEP_WITH_PREVIOUS = 'keep-with-previous',
  LANGUAGE = 'language',
  LAST_LINE_END_INDENT = 'last-line-end-indent',
  LEADER_ALIGNMENT = 'leader-alignment',
  LEADER_LENGTH = 'leader-length',
  LEADER_PATTERN = 'leader-pattern',
  LEADER_PATTERN_WIDTH = 'leader-pattern-width',
  LEFT = 'left',
  LETTER_SPACING = 'letter-spacing',
  LETTER_VALUE = 'letter-value',
  LINEFEED_TREATMENT = 'linefeed-treatment',
  LINE_HEIGHT = 'line-height',
  LINE_HEIGHT_SHIFT_ADJUSTMENT = 'line-height-shift-adjustment',
  LINE_STACKING_STRATEGY = 'line-stacking-strategy',
  MARGIN_BOTTOM = 'margin-bottom',
  MARGIN_LEFT = 'margin-left',
  MARGIN_RIGHT = 'margin-right',
  MARGIN_TOP = 'margin-top',
  MARKER_CLASS_NAME = 'marker-class-name',
  MASTER_NAME = 'master-name',
  MASTER_REFERENCE = 'master-reference',
  MAXIMUM_REPEATS = 'maximum-repeats',
  MEDIA_USAGE = 'media-usage',
  MERGE_PAGES_ACROSS_INDEX_KEY_REFERENCES = 'merge-pages-across-index-key-references',
  MERGE_RANGES_ACROSS_INDEX_KEY_REFERENCES = 'merge-ranges-across-index-key-references',
  MERGE_SEQUENTIAL_PAGE_NUMBERS = 'merge-sequential-page-numbers',
  NUMBER_COLUMNS_REPEATED = 'number-columns-repeated',
  NUMBER_COLUMNS_SPANNED = 'number-columns-spanned',
  NUMBER_ROWS_SPANNED = 'number-rows-spanned',
  ODD_OR_EVEN = 'odd-or-even',
  ORPHANS = 'orphans',
  OVERFLOW = 'overflow',
  PADDING_AFTER = 'padding-after',
  PADDING_BEFORE = 'padding-before',
  PADDING_BOTTOM = 'padding-bottom',
  PADDING_END = 'padding-end',
  PADDING_LEFT = 'padding-left',
  PADDING_RIGHT = 'padding-right',
  PADDING_START = 'padding-start',
  PADDING_TOP = 'padding-top',
  PAGE_CITATION_STRATEGY = 'page-citation-strategy',
  PAGE_HEIGHT = 'page-height',
  PAGE_NUMBER_TREATMENT = 'page-number-treatment',
  PAGE_POSITION = 'page-position',
  PAGE_WIDTH = 'page-width',
  PAUSE_AFTER = 'pause-after',
  PAUSE_BEFORE = 'pause-before',
  PITCH = 'pitch',
  PITCH_RANGE = 'pitch-range',
  PLAY_DURING = 'play-during',
  PRECEDENCE = 'precedence',
  PROVISIONAL_DISTANCE_BETWEEN_STARTS = 'provisional-distance-between-starts',
  PROVISIONAL_LABEL_SEPARATION = 'provisional-label-separation',
  REFERENCE_ORIENTATION = 'reference-orientation',
  REF_ID = 'ref-id',
  REF_INDEX_KEY = 'ref-index-key',
  REGION_NAME = 'region-name',
  REGION_NAME_REFERENCE = 'region-name-reference',
  RELATIVE_ALIGN = 'relative-align',
  RELATIVE_POSITION = 'relative-position',
  RENDERING_INTENT = 'rendering-intent',
  RETRIEVE_BOUNDARY = 'retrieve-boundary',
  RETRIEVE_BOUNDARY_WITHIN_TABLE = 'retrieve-boundary-within-table',
  RETRIEVE_CLASS_NAME = 'retrieve-class-name',
  RETRIEVE_POSITION = 'retrieve-position',
  RETRIEVE_POSITION_WITHIN_TABLE = 'retrieve-position-within-table',
  RICHNESS = 'richness',
  RIGHT = 'right',
  ROLE = 'role',
  RULE_STYLE = 'rule-style',
  RULE_THICKNESS = 'rule-thickness',
  SCALE_OPTION = 'scale-option',
  SCALING = 'scaling',
  SCALING_METHOD = 'scaling-method',
  SCORE_SPACES = 'score-spaces',
  SCRIPT = 'script',
  SHOW_DESTINATION = 'show-destination',
  SOURCE_DOCUMENT = 'source-document',
  SPACE_AFTER = 'space-after',
  SPACE_BEFORE = 'space-before',
  SPACE_END = 'space-end',
  SPACE_START = 'space-start',
  SPAN = 'span',
  SPEAK = 'speak',
  SPEAK_HEADER = 'speak-header',
  SPEAK_NUMERAL = 'speak-numeral',
  SPEAK_PUNCTUATION = 'speak-punctuation',
  SPEECH_RATE = 'speech-rate',
  SRC = 'src',
  START_INDENT = 'start-indent',
  STARTING_STATE = 'starting-state',
  STARTS_ROW = 'starts-row',
  STRESS = 'stress',
  SUPPRESS_AT_LINE_BREAK = 'suppress-at-line-break',
  SWITCH_TO = 'switch-to',
  TABLE_LAYOUT = 'table-layout',
  TABLE_OMIT_FOOTER_AT_BREAK = 'table-omit-footer-at-break',
  TABLE_OMIT_HEADER_AT_BREAK = 'table-omit-header-at-break',
  TARGET_PRESENTATION_CONTEXT = 'target-presentation-context',
  TARGET_PROCESSING_CONTEXT = 'target-processing-context',
  TARGET_STYLESHEET = 'target-stylesheet',
  TEXT_ALIGN = 'text-align',
  TEXT_ALIGN_LAST = 'text-align-last',
  TEXT_ALTITUDE = 'text-altitude',
  TEXT_DECORATION = 'text-decoration',
  TEXT_DEPTH = 'text-depth',
  TEXT_INDENT = 'text-indent',
  TEXT_SHADOW = 'text-shadow',
  TEXT_TRANSFORM = 'text-transform',
  TOP = 'top',
  TREAT_AS_WORD_SPACE = 'treat-as-word-space',
  UNICODE_BIDI = 'unicode-bidi',
  VISIBILITY = 'visibility',
  VOICE_FAMILY = 'voice-family',
  VOLUME = 'volume',
  WHITE_SPACE = 'white-space',
  WHITE_SPACE_COLLAPSE = 'white-space-collapse',
  WHITE_SPACE_TREATMENT = 'white-space-treatment',
  WIDOWS = 'widows',
  WIDTH = 'width',
  WORD_SPACING = 'word-spacing',
  WRAP_OPTION = 'wrap-option',
  WRITING_MODE = 'writing-mode',
  Z_INDEX = 'z-index',
}
export enum CustomProperty {
  PREFIX = 'prefix',
  LINE_NUMBERING = 'line-numbering',
  DL_TYPE = 'dl-type',
  OL_1 = 'ol-1',
  OL_2 = 'ol-2',
  OL_3 = 'ol-3',
  OL_4 = 'ol-4',
  OL_BEFORE_1 = 'ol-before-1',
  OL_BEFORE_2 = 'ol-before-2',
  OL_BEFORE_3 = 'ol-before-3',
  OL_BEFORE_4 = 'ol-before-4',
  OL_AFTER_1 = 'ol-after-1',
  OL_AFTER_2 = 'ol-after-2',
  OL_AFTER_3 = 'ol-after-3',
  OL_AFTER_4 = 'ol-after-4',
  OL_SUBLEVEL = 'ol-sublevel',
  UL_1 = 'ul-1',
  UL_2 = 'ul-2',
  UL_3 = 'ul-3',
  UL_4 = 'ul-4',
  CAPTION_NUMBER = 'caption-number',
  CAPTION_POSITION = 'caption-position',
  SYMBOL_SCOPE = 'symbol-scope',
  LINK_PAGE_NUMBER = 'link-page-number',
  LINK_URL = 'link-url',
  TITLE_NUMBERING = 'title-numbering',
  ICON = 'icon',
  LINE_HEIGHT_LIST = 'line-height-list',
  COLOR_LIST = 'color-list',
  BACKGROUND_COLOR_LIST = 'background-color-list',
  BORDER_LIST = 'border_list',
}

export type Property = FoProperty | CustomProperty;

export type Style =
  | {
      default: string | boolean | number;
      inherit: StyleName | undefined;
    }
  | {
      default: undefined;
      inherit: StyleName;
    };

export enum StyleName {
  BODY = 'body',
  TOPIC = 'topic',
  TOPIC_TOPIC = 'topic_topic',
  TOPIC_TOPIC_TOPIC = 'topic_topic_topic',
  TOPIC_TOPIC_TOPIC_TOPIC = 'topic_topic_topic_topic',
  SECTION = 'section',
  SECTION_TITLE = 'section_title',
  PRE = 'pre',
  EXAMPLE = 'example',
  NOTE = 'note',
  EXAMPLE_TITLE = 'example_title',
  CODEBLOCK = 'codeblock',
  DL = 'dl',
  OL = 'ol',
  UL = 'ul',
  TABLE = 'table',
  FIG = 'fig',
  TOC_1 = 'toc_1',
  TOC_2 = 'toc_2',
  TOC_3 = 'toc_3',
  TOC_4 = 'toc_4',
  LINK = 'link',
  TM = 'tm',
}

export type StyleConfiguration = Record<
  StyleName,
  Partial<Record<Property, Style>>
>;

export const convertBorder = (border: string = 'none') => {
  switch (border) {
    case 'none':
      return {
        'border-before-style': 'none',
        'border-before-width': '1pt',
        'border-before-color': 'black',
        'border-end-style': 'none',
        'border-end-width': '1pt',
        'border-end-color': 'black',
        'border-after-style': 'none',
        'border-after-width': '1pt',
        'border-after-color': 'black',
        'border-start-style': 'none',
        'border-start-width': '1pt',
        'border-start-color': 'black',
      };
    case 'all':
      return {
        'border-before-style': 'solid',
        'border-before-width': '1pt',
        'border-before-color': 'black',
        'border-end-style': 'solid',
        'border-end-width': '1pt',
        'border-end-color': 'black',
        'border-after-style': 'solid',
        'border-after-width': '1pt',
        'border-after-color': 'black',
        'border-start-style': 'solid',
        'border-start-width': '1pt',
        'border-start-color': 'black',
      };
    case 'top':
      return {
        'border-before-style': 'solid',
        'border-before-width': '1pt',
        'border-before-color': 'black',
        'border-end-style': 'none',
        'border-end-width': '1pt',
        'border-end-color': 'black',
        'border-after-style': 'none',
        'border-after-width': '1pt',
        'border-after-color': 'black',
        'border-start-style': 'none',
        'border-start-width': '1pt',
        'border-start-color': 'black',
      };
    case 'bottom':
      return {
        'border-before-style': 'none',
        'border-before-width': '1pt',
        'border-before-color': 'black',
        'border-end-style': 'none',
        'border-end-width': '1pt',
        'border-end-color': 'black',
        'border-after-style': 'solid',
        'border-after-width': '1pt',
        'border-after-color': 'black',
        'border-start-style': 'none',
        'border-start-width': '1pt',
        'border-start-color': 'black',
      };
    case 'topbot':
      return {
        'border-before-style': 'solid',
        'border-before-width': '1pt',
        'border-before-color': 'black',
        'border-end-style': 'none',
        'border-end-width': '1pt',
        'border-end-color': 'black',
        'border-after-style': 'solid',
        'border-after-width': '1pt',
        'border-after-color': 'black',
        'border-start-style': 'none',
        'border-start-width': '1pt',
        'border-start-color': 'black',
      };
    case 'sides':
      return {
        'border-before-style': 'none',
        'border-before-width': '1pt',
        'border-before-color': 'black',
        'border-end-style': 'solid',
        'border-end-width': '1pt',
        'border-end-color': 'black',
        'border-after-style': 'none',
        'border-after-width': '1pt',
        'border-after-color': 'black',
        'border-start-style': 'solid',
        'border-start-width': '1pt',
        'border-start-color': 'black',
      };
  }
};

const startIndent = 25;
const base: Partial<Record<Property, Style>> = {
  'background-color': { default: 'transparent', inherit: undefined },
  'padding-top': { default: '0pt', inherit: undefined },
  'padding-right': { default: '0pt', inherit: undefined },
  'padding-bottom': { default: '0pt', inherit: undefined },
  'padding-left': { default: '0pt', inherit: undefined },
  'end-indent': { default: '0pt', inherit: undefined },
  border_list: { default: 'none', inherit: undefined },
  ...Object.fromEntries(
    Object.entries(convertBorder('none')!).map(([name, value]) => [
      name,
      { default: value, inherit: undefined },
    ])
  ),
};

export const headerFooterStyles: Partial<Record<FoProperty, Style>> = {
  ...base,
  'font-family': { default: 'Times New Roman', inherit: undefined },
  'font-size': { default: '10pt', inherit: undefined },
  color: { default: 'black', inherit: undefined },
  'font-weight': { default: 'normal', inherit: undefined },
  'font-style': { default: 'normal', inherit: undefined },
  'text-decoration': { default: 'none', inherit: undefined },
  'space-before': { default: '6pt', inherit: undefined },
  'space-after': { default: '6pt', inherit: undefined },
  'text-align': { default: 'start', inherit: undefined },
  'start-indent': { default: startIndent + 'pt', inherit: undefined },
  'line-height': { default: '1.2', inherit: undefined },
};

export const styles: StyleConfiguration = {
  // block
  [StyleName.BODY]: {
    ...base,
    'font-family': { default: 'Times New Roman', inherit: undefined },
    'font-size': { default: '10pt', inherit: undefined },
    color: { default: 'black', inherit: undefined },
    'font-weight': { default: 'normal', inherit: undefined },
    'font-style': { default: 'normal', inherit: undefined },
    'text-decoration': { default: 'none', inherit: undefined },
    'space-before': { default: '6pt', inherit: undefined },
    'space-after': { default: '6pt', inherit: undefined },
    'text-align': { default: 'start', inherit: undefined },
    'start-indent': { default: startIndent + 'pt', inherit: undefined },
    'line-height': { default: '1.2', inherit: undefined },
  },
  [StyleName.TOPIC]: {
    ...base,
    'font-family': { default: 'Helvetica', inherit: undefined },
    'font-size': { default: '18pt', inherit: undefined },
    color: { default: 'black', inherit: StyleName.BODY },
    'font-weight': { default: 'bold', inherit: undefined },
    'font-style': { default: 'normal', inherit: undefined },
    'text-decoration': { default: 'none', inherit: StyleName.BODY },
    'space-before': { default: '0pt', inherit: undefined },
    'space-after': { default: '16.8pt', inherit: undefined },
    'text-align': { default: 'start', inherit: undefined },
    'start-indent': { default: '0pt', inherit: undefined },
    'padding-top': { default: '0pt', inherit: undefined },
    'padding-right': { default: '0pt', inherit: undefined },
    'padding-bottom': { default: '0pt', inherit: undefined },
    'padding-left': { default: '0pt', inherit: undefined },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    // custom
    'title-numbering': { default: true, inherit: undefined },
  },
  [StyleName.TOPIC_TOPIC]: {
    ...base,
    'font-family': { default: 'Helvetica', inherit: undefined },
    'font-size': { default: '14pt', inherit: undefined },
    color: { default: 'black', inherit: StyleName.BODY },
    'font-weight': { default: 'bold', inherit: undefined },
    'font-style': { default: 'normal', inherit: undefined },
    'text-decoration': { default: 'none', inherit: StyleName.BODY },
    'space-before': { default: '12pt', inherit: undefined },
    'space-after': { default: '5pt', inherit: undefined },
    'text-align': { default: 'start', inherit: undefined },
    'start-indent': { default: '0pt', inherit: undefined },
    'padding-top': { default: '0pt', inherit: undefined },
    'padding-right': { default: '0pt', inherit: undefined },
    'padding-bottom': { default: '0pt', inherit: undefined },
    'padding-left': { default: '0pt', inherit: undefined },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    // custom
    'title-numbering': { default: true, inherit: undefined },
  },
  [StyleName.TOPIC_TOPIC_TOPIC]: {
    ...base,
    'font-family': { default: 'Helvetica', inherit: undefined },
    'font-size': { default: '12pt', inherit: undefined },
    color: { default: 'black', inherit: StyleName.BODY },
    'font-weight': { default: 'bold', inherit: undefined },
    'font-style': { default: 'normal', inherit: undefined },
    'text-decoration': { default: 'none', inherit: StyleName.BODY },
    'space-before': { default: '12pt', inherit: undefined },
    'space-after': { default: '2pt', inherit: undefined },
    'text-align': { default: 'start', inherit: undefined },
    'start-indent': { default: '0pt', inherit: undefined },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    // custom
    'title-numbering': { default: true, inherit: undefined },
  },
  [StyleName.TOPIC_TOPIC_TOPIC_TOPIC]: {
    ...base,
    'font-family': { default: 'Helvetica', inherit: StyleName.BODY },
    'font-size': { default: '10pt', inherit: StyleName.BODY },
    color: { default: 'black', inherit: StyleName.BODY },
    'font-weight': { default: 'bold', inherit: undefined },
    'font-style': { default: 'normal', inherit: undefined },
    'text-decoration': { default: 'none', inherit: StyleName.BODY },
    'space-before': { default: '12pt', inherit: undefined },
    'space-after': { default: '0pt', inherit: undefined },
    'text-align': { default: 'start', inherit: undefined },
    'start-indent': { default: '0pt', inherit: StyleName.BODY },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    // custom
    'title-numbering': { default: true, inherit: undefined },
  },
  [StyleName.SECTION]: {
    ...base,
    'font-family': { default: undefined, inherit: StyleName.BODY },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: undefined, inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    'space-before': { default: '6pt', inherit: StyleName.BODY },
    'space-after': { default: '6pt', inherit: StyleName.BODY },
    'text-align': { default: undefined, inherit: StyleName.BODY },
    'start-indent': { default: undefined, inherit: StyleName.BODY },
    'line-height': { default: undefined, inherit: StyleName.BODY },
  },
  [StyleName.SECTION_TITLE]: {
    ...base,
    'font-family': { default: 'Helvetica', inherit: undefined },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: 'bold', inherit: undefined },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    'space-before': { default: undefined, inherit: StyleName.BODY },
    'space-after': { default: undefined, inherit: StyleName.BODY },
    'text-align': { default: undefined, inherit: StyleName.BODY },
    'start-indent': { default: undefined, inherit: StyleName.BODY },
    'line-height': { default: undefined, inherit: StyleName.BODY },
  },
  [StyleName.EXAMPLE]: {
    ...base,
    'font-family': { default: undefined, inherit: StyleName.BODY },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: undefined, inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    'space-before': { default: '6pt', inherit: StyleName.BODY },
    'space-after': { default: '6pt', inherit: StyleName.BODY },
    'text-align': { default: undefined, inherit: StyleName.BODY },
    // "padding-top": {default: "5pt", inherit: undefined},
    // "padding-right": {default: "5pt", inherit: undefined},
    // "padding-bottom": {default: "5pt", inherit: undefined},
    // "padding-left": {default: "5pt", inherit: undefined},
    'start-indent': { default: undefined, inherit: StyleName.BODY },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    // "border-before-style": {default: "solid", inherit: undefined},
    // "border-before-width": {default: "1pt", inherit: undefined},
    // "border-before-color": {default: "black", inherit: undefined},
    // "border-end-style": {default: "solid", inherit: undefined},
    // "border-end-width": {default: "1pt", inherit: undefined},
    // "border-end-color": {default: "black", inherit: undefined},
    // "border-after-style": {default: "solid", inherit: undefined},
    // "border-after-width": {default: "1pt", inherit: undefined},
    // "border-after-color": {default: "black", inherit: undefined},
    // "border-start-style": {default: "solid", inherit: undefined},
    // "border-start-width": {default: "1pt", inherit: undefined},
    // "border-start-color": {default: "black", inherit: undefined},
  },
  [StyleName.EXAMPLE_TITLE]: {
    ...base,
    'font-family': { default: 'Helvetica', inherit: undefined },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: 'bold', inherit: undefined },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    'space-before': { default: undefined, inherit: StyleName.BODY },
    'space-after': { default: undefined, inherit: StyleName.BODY },
    'text-align': { default: undefined, inherit: StyleName.BODY },
    'start-indent': { default: undefined, inherit: StyleName.BODY },
    'line-height': { default: undefined, inherit: StyleName.BODY },
  },
  [StyleName.NOTE]: {
    ...base,
    'font-family': { default: undefined, inherit: StyleName.BODY },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: undefined, inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    'space-before': { default: undefined, inherit: StyleName.BODY },
    'space-after': { default: undefined, inherit: StyleName.BODY },
    'text-align': { default: undefined, inherit: StyleName.BODY },
    'start-indent': { default: undefined, inherit: StyleName.BODY },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    // custom
    icon: { default: false, inherit: undefined },
  },
  [StyleName.PRE]: {
    ...base,
    'font-family': { default: 'Courier New', inherit: undefined },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: undefined, inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    'space-before': { default: '15pt', inherit: undefined },
    'space-after': { default: undefined, inherit: StyleName.BODY },
    'text-align': { default: undefined, inherit: StyleName.BODY },
    'start-indent': { default: undefined, inherit: StyleName.BODY },
    'line-height': { default: undefined, inherit: StyleName.BODY },
  },
  [StyleName.CODEBLOCK]: {
    ...base,
    'font-family': { default: 'Courier New', inherit: undefined },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: undefined, inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    'space-before': { default: '15pt', inherit: undefined },
    'space-after': { default: undefined, inherit: StyleName.BODY },
    'text-align': { default: undefined, inherit: StyleName.BODY },
    'start-indent': { default: undefined, inherit: StyleName.BODY },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    // custom
    'line-numbering': { default: false, inherit: undefined },
  },
  [StyleName.DL]: {
    ...base,
    'font-family': { default: undefined, inherit: StyleName.BODY },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: undefined, inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    'space-before': { default: undefined, inherit: StyleName.BODY },
    'space-after': { default: undefined, inherit: StyleName.BODY },
    'text-align': { default: undefined, inherit: StyleName.BODY },
    'start-indent': { default: undefined, inherit: StyleName.BODY },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    // custom
    'dl-type': { default: 'table', inherit: undefined },
  },
  [StyleName.OL]: {
    ...base,
    'font-family': { default: undefined, inherit: StyleName.BODY },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: undefined, inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    'space-before': { default: undefined, inherit: StyleName.BODY },
    'space-after': { default: undefined, inherit: StyleName.BODY },
    'text-align': { default: undefined, inherit: StyleName.BODY },
    'start-indent': { default: undefined, inherit: StyleName.BODY },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    // custom
    'ol-1': { default: '1', inherit: undefined },
    'ol-2': { default: '1', inherit: undefined },
    'ol-3': { default: '1', inherit: undefined },
    'ol-4': { default: '1', inherit: undefined },
    'ol-before-1': { default: '', inherit: undefined },
    'ol-before-2': { default: '', inherit: undefined },
    'ol-before-3': { default: '', inherit: undefined },
    'ol-before-4': { default: '', inherit: undefined },
    'ol-after-1': { default: '. ', inherit: undefined },
    'ol-after-2': { default: '. ', inherit: undefined },
    'ol-after-3': { default: '. ', inherit: undefined },
    'ol-after-4': { default: '. ', inherit: undefined },
    'ol-sublevel': { default: false, inherit: undefined },
  },
  [StyleName.UL]: {
    ...base,
    'font-family': { default: undefined, inherit: StyleName.BODY },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: undefined, inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    'space-before': { default: undefined, inherit: StyleName.BODY },
    'space-after': { default: undefined, inherit: StyleName.BODY },
    'text-align': { default: undefined, inherit: StyleName.BODY },
    'start-indent': { default: undefined, inherit: StyleName.BODY },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    // custom
    'ul-1': { default: '\u2022', inherit: undefined },
    'ul-2': { default: '\u2022', inherit: undefined },
    'ul-3': { default: '\u2022', inherit: undefined },
    'ul-4': { default: '\u2022', inherit: undefined },
  },
  [StyleName.TABLE]: {
    ...base,
    'font-family': { default: undefined, inherit: StyleName.BODY },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: undefined, inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    'space-before': { default: undefined, inherit: StyleName.BODY },
    'space-after': { default: undefined, inherit: StyleName.BODY },
    'text-align': { default: undefined, inherit: StyleName.BODY },
    'start-indent': { default: undefined, inherit: StyleName.BODY },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    // custom
    'caption-number': { default: 'document', inherit: undefined },
    'caption-position': { default: 'before', inherit: undefined },
  },
  [StyleName.FIG]: {
    ...base,
    'font-family': { default: undefined, inherit: StyleName.BODY },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: undefined, inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    'space-before': { default: undefined, inherit: StyleName.BODY },
    'space-after': { default: undefined, inherit: StyleName.BODY },
    'text-align': { default: undefined, inherit: StyleName.BODY },
    'start-indent': { default: undefined, inherit: StyleName.BODY },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    // custom
    'caption-number': { default: 'document', inherit: undefined },
    'caption-position': { default: 'after', inherit: undefined },
  },
  [StyleName.TOC_1]: {
    ...base,
    'font-family': { default: undefined, inherit: StyleName.BODY },
    'font-size': { default: '14pt', inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: 'bold', inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    // "space-before": {default: undefined, inherit: "body"},
    // "space-after": {default: undefined, inherit: "body"},
    'text-align': { default: undefined, inherit: StyleName.BODY },
    'start-indent': {
      default: startIndent + 0 * 30 + 'pt',
      inherit: StyleName.BODY,
    },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    'padding-top': { default: '20pt', inherit: undefined },
    'space-before': { default: '0pt', inherit: undefined },
    'space-after': { default: '0pt', inherit: undefined },
    // custom
    prefix: { default: true, inherit: undefined },
  },
  [StyleName.TOC_2]: {
    ...base,
    'font-family': { default: undefined, inherit: StyleName.BODY },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: undefined, inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    // "space-before": {default: undefined, inherit: "body"},
    // "space-after": {default: undefined, inherit: "body"},
    'text-align': { default: undefined, inherit: StyleName.BODY },
    'start-indent': {
      default: startIndent + 1 * 30 + 'pt',
      inherit: StyleName.BODY,
    },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    'space-before': { default: '0pt', inherit: undefined },
    'space-after': { default: '0pt', inherit: undefined },
  },
  [StyleName.TOC_3]: {
    ...base,
    'font-family': { default: undefined, inherit: StyleName.BODY },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: undefined, inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    // "space-before": {default: undefined, inherit: "body"},
    // "space-after": {default: undefined, inherit: "body"},
    'text-align': { default: undefined, inherit: StyleName.BODY },
    'start-indent': {
      default: startIndent + 2 * 30 + 'pt',
      inherit: StyleName.BODY,
    },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    'space-before': { default: '0pt', inherit: undefined },
    'space-after': { default: '0pt', inherit: undefined },
  },
  [StyleName.TOC_4]: {
    ...base,
    'font-family': { default: undefined, inherit: StyleName.BODY },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'font-weight': { default: undefined, inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    // "space-before": {default: undefined, inherit: "body"},
    // "space-after": {default: undefined, inherit: "body"},
    'text-align': { default: undefined, inherit: StyleName.BODY },
    'start-indent': {
      default: startIndent + 3 * 30 + 'pt',
      inherit: StyleName.BODY,
    },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    'space-before': { default: '0pt', inherit: undefined },
    'space-after': { default: '0pt', inherit: undefined },
  },
  // inline
  [StyleName.LINK]: {
    'font-family': { default: undefined, inherit: StyleName.BODY },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: 'blue', inherit: undefined },
    'background-color': { default: 'transparent', inherit: undefined },
    'font-weight': { default: undefined, inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    // custom
    'link-page-number': { default: true, inherit: undefined },
    'link-url': { default: false, inherit: undefined },
  },
  [StyleName.TM]: {
    'font-family': { default: undefined, inherit: StyleName.BODY },
    'font-size': { default: undefined, inherit: StyleName.BODY },
    color: { default: undefined, inherit: StyleName.BODY },
    'background-color': { default: 'transparent', inherit: undefined },
    'font-weight': { default: undefined, inherit: StyleName.BODY },
    'font-style': { default: undefined, inherit: StyleName.BODY },
    'text-decoration': { default: undefined, inherit: StyleName.BODY },
    'line-height': { default: undefined, inherit: StyleName.BODY },
    // custom
    'symbol-scope': { default: 'always', inherit: undefined },
  },
};
